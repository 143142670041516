import { useAuthStore } from "~/store/auth"

export default defineNuxtRouteMiddleware((to, from) => {
    const { user } = useAuthStore()    

    if(!user)
        return
    
    //TODO: para funcionar tem que atualizar sessão no server (não sei como fazer)
    // if (!user?.value.name && to.path !== '/user/onboarding') {        
    //     return navigateTo('/user/onboarding')
    // }
    // else if (!user?.value.company && to.path !== '/company/onboarding') {        
    //     return navigateTo('/company/onboarding')
    // }
})